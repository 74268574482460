import React from 'react';
import { Link } from "gatsby";
import SocialIcons from "../SocialIcons";
import LogoUni from "../../svg/LogoTecno";
import LogoTecno from '../../svg/LogoTecno';


const Footer = () => (

  <footer className="text-white bg-blue-50">



  <h2 id="footer-heading" class="sr-only">Footer</h2>
  <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
    <div class="xl:grid xl:grid-cols-3 xl:gap-8">
      <div class="space-y-8 xl:col-span-1">
        
      <div className="w-28 lg:w-30 mx-auto mb-8"><LogoTecno/></div>
        <p class="text-gray-600 text-base">
        Optimicemos juntos los recursos de tu empresa 
        </p>
        <div class="flex space-x-6">
          <a href=" https://www.facebook.com/Tecnoclean-102262928939832"  class="text-gray-600 hover:text-gray-500">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
            </svg>
          </a>
          
          <a href="https://www.linkedin.com/company/tecnocleanec" class="text-gray-600 hover:text-gray-500">
          <span class="sr-only">Linkedin</span>
          <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true" >
            <path fill="#828282" d="M150.65,100.682c0,27.992-22.508,50.683-50.273,50.683c-27.765,0-50.273-22.691-50.273-50.683
            C50.104,72.691,72.612,50,100.377,50C128.143,50,150.65,72.691,150.65,100.682z M143.294,187.333H58.277V462h85.017V187.333z M279.195,187.333h-81.541V462h81.541c0,0,0-101.877,0-144.181c0-38.624,17.779-61.615,51.807-61.615 c31.268,0,46.289,22.071,46.289,61.615c0,39.545,0,144.181,0,144.181h84.605c0,0,0-100.344,0-173.915
            s-41.689-109.131-99.934-109.131s-82.768,45.369-82.768,45.369V187.333z"/>
           </svg>
          </a>



          <a href="#" class="text-gray-600 hover:text-gray-500">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
            </svg>
          </a>

        </div>
      </div>
      <div class="mt-12 grid grid-cols-1 gap-8 xl:mt-0 xl:col-span-2">
        <div class="md:grid md:grid-cols-2 md:gap-8">
          <div>
            <h3 class="text-sm font-semibold text-gray-600 tracking-wider uppercase">
            Contáctenos
            </h3>
            <ul role="list" class="mt-4 space-y-4">
              <li class="text-base text-gray-500 ">
                De los Eucaliptos E1-140 y Psje. Juncos <br/>170307 <br/>Quito, Ecuador
              </li>

              <li>
                <a href="https://walink.co/f18c98" target="_blank"  rel="noreferrer" class="text-base text-gray-500 hover:text-gray-900">
                (+593) 99 488 9634
                </a>
              </li>

              <li class="text-base text-gray-500">  
                info@tecnocleanecuador.ec 
              </li>
              <li class="text-base text-gray-500 ">
                  
                Lun - Vie, 8:30 - 17:00 GMT -5:00.
              </li>
            </ul>
            </div>

            <div class="mt-12 md:mt-0">
            <h3 class="text-sm font-semibold text-gray-600 tracking-wider uppercase">
              Menú
            </h3>
            <ul role="list" class="mt-4 space-y-4">
              <li>
                <a href="#nosotros" class="text-base text-gray-500 hover:text-gray-900">
                Quiénes somos
                </a>
              </li>

              <li>
                <a href="#catalogo" class="text-base text-gray-500 hover:text-gray-900">
                Catálogo
                </a>
              </li>

              <li>
              <Link className="text-base text-gray-500 hover:text-gray-900" to="/legal/privacy-policy">Política de privacidad</Link>
              </li>

              <li>
              <Link className="text-base text-gray-500 hover:text-gray-900" to="/legal/terminos-y-condiciones">Términos y condiciones de uso</Link>
              </li>
            </ul>
          </div>
        </div>

        
      </div>
    </div>
    
    <div class="mt-12 border-t border-primary-default pt-8">
      <p class="text-base text-gray-400 xl:text-center">
        &copy; 2021 Tecnoclean - Todos los derechos reservado 
      </p>
    </div>
  </div>
</footer>

);

export default Footer;
