import React from 'react';
import "./tecno.css"
const LogoTecno = ({className}) => (
  <svg viewBox="0 0 595.28 215.89"  xmlns="http://www.w3.org/2000/svg" className={`focus:ouline-none ${className}`}>
<title>Tecnoclean</title>
<desc>Soluciones integrales personalizadas para su lavandería; contribuyendo a la limpieza, higiene, cuidado, conservación y preservación textil de sus prendas.</desc>


<g>
	<g>
		<path className="st0" d="M277.5,147.56c-0.52,0-1.05-0.01-1.56-0.03c21.03-0.82,37.82-18.05,37.82-39.18
			c0-21.13-16.79-38.36-37.82-39.18c0.52-0.02,1.04-0.03,1.56-0.03c21.75,0,39.38,17.55,39.38,39.21S299.25,147.56,277.5,147.56z"/>
		<path className="st1" d="M274.37,147.56c-21.75,0-39.38-17.55-39.38-39.21s17.63-39.21,39.38-39.21c0.52,0,1.05,0.01,1.56,0.03
			c-21.03,0.82-37.82,18.04-37.82,39.18c0,21.13,16.79,38.36,37.82,39.18C275.42,147.55,274.89,147.56,274.37,147.56z"/>
	</g>
	<g>
		<path className="st2" d="M277.39,160.84l-1.46-0.02c28.05-0.78,50.56-23.97,50.56-52.47c0-28.5-22.5-51.69-50.56-52.47l1.46-0.02
			c28.73,0,52.01,23.5,52.01,52.49S306.12,160.84,277.39,160.84z"/>
		<path className="st2" d="M274.48,160.84c-28.73,0-52.01-23.5-52.01-52.49s23.29-52.49,52.01-52.49l1.46,0.02
			c-28.05,0.78-50.56,23.97-50.56,52.47c0,28.5,22.5,51.69,50.56,52.47L274.48,160.84z"/>
	</g>
	<g>
		<path className="st3" d="M276.86,171.3l-0.93-0.01c34.4-0.5,62.14-28.48,62.14-62.94s-27.74-62.44-62.14-62.94l0.93-0.01
			c34.83,0,63.07,28.18,63.07,62.95S311.7,171.3,276.86,171.3z"/>
		<path className="st3" d="M275,171.3c-34.83,0-63.07-28.18-63.07-62.95S240.17,45.41,275,45.41l0.93,0.01
			c-34.4,0.5-62.14,28.48-62.14,62.94s27.74,62.44,62.14,62.94L275,171.3z"/>
	</g>
	<g>
		<path className="st4" d="M277.06,184.78l-1.13-0.01c41.77-0.6,75.45-34.58,75.45-76.41c0-41.83-33.68-75.81-75.45-76.42l1.13-0.01
			c42.29,0,76.58,34.22,76.58,76.42S319.35,184.78,277.06,184.78z"/>
		<path className="st4" d="M274.81,184.78c-42.29,0-76.58-34.21-76.58-76.42s34.28-76.42,76.58-76.42l1.13,0.01
			c-41.77,0.6-75.45,34.58-75.45,76.42c0,41.83,33.68,75.81,75.45,76.41L274.81,184.78z"/>
	</g>
	<polygon className="st5" points="93.28,76.39 109.65,76.39 112.4,65.8 61.11,65.8 58.37,76.39 75.78,76.39 61.2,132.6 78.71,132.6 	"/>
	<path className="st5" d="M125.18,117.65c-0.85,2.84-2.65,8.9-10.03,8.9c-4.16,0-7.1-2.46-7.1-7.57c0-3.03,0.76-5.87,1.42-8.7h32.17
		c0.95-3.41,1.61-6.63,1.61-10.22c0-13.25-11.07-15.9-20.34-15.9c-15.71,0-21.67,7.66-24.79,14.38c-2.65,5.39-4.54,13.63-4.54,19.21
		c0,11.54,8.14,15.99,20.35,15.99c11.45,0,17.6-3.97,20.63-6.81c3.79-3.5,4.83-6.62,5.68-9.27H125.18z M111.36,103.65
		c1.7-5.58,4.26-12.49,11.35-12.49c4.07,0,6.34,2.65,6.34,6.62c0,2.37-0.66,4.92-0.95,5.87H111.36z"/>
	<path className="st5" d="M178.14,117.37c-2.18,7.19-5.68,9.27-9.75,9.27c-3.41,0-6.81-1.8-6.81-7.76c0-5.49,2.93-16.75,5.96-22.05
		c1.61-2.84,3.88-5.68,8.61-5.68c3.69,0,6.15,2.18,6.15,6.53c0,1.23-0.19,2.18-0.38,3.5h14.67c0.09-1.13,0.19-1.7,0.19-2.65
		c0-14-15.99-14.38-20.44-14.38c-3.6,0-11.64,0.28-18.45,5.77c-6.53,5.39-8.8,13.15-9.94,17.98c-0.66,2.84-1.23,6.06-1.23,9.46
		c0,14.19,11.92,16.37,21.2,16.37c17.6,0,23.18-9.37,25.27-16.37H178.14z"/>
	<path className="st5" d="M245.71,103.17c0.76-2.84,1.61-6.06,1.61-9.08c0-5.3-3.31-9.65-10.5-9.65c-6.06,0-10.98,2.65-15.71,6.34
		l1.42-5.49H207.2l-12.3,47.31h15.42l7.85-30.28c1.04-4.26,1.61-5.58,3.31-7.29c1.7-1.61,3.88-2.65,5.87-2.65
		c3.22,0,4.16,2.37,4.16,4.26c0,1.7-0.85,4.92-1.51,7.29l-7.38,28.67h15.52L245.71,103.17z"/>
	<path className="st5" d="M337.73,117.37c-2.18,7.19-5.68,9.27-9.75,9.27c-3.41,0-6.81-1.8-6.81-7.76c0-5.49,2.93-16.75,5.96-22.05
		c1.61-2.84,3.88-5.68,8.61-5.68c3.69,0,6.15,2.18,6.15,6.53c0,1.23-0.19,2.18-0.38,3.5h14.67c0.09-1.13,0.19-1.7,0.19-2.65
		c0-14-15.99-14.38-20.44-14.38c-3.6,0-11.64,0.28-18.45,5.77c-6.53,5.39-8.8,13.15-9.94,17.98c-0.66,2.84-1.23,6.06-1.23,9.46
		c0,14.19,11.92,16.37,21.2,16.37c17.6,0,23.18-9.37,25.27-16.37H337.73z"/>
	<polygon className="st5" points="387.13,65.8 371.61,65.8 354.49,132.6 370,132.6 	"/>
	<path className="st5" d="M414.86,117.65c-0.85,2.84-2.65,8.9-10.03,8.9c-4.16,0-7.1-2.46-7.1-7.57c0-3.03,0.76-5.87,1.42-8.7h32.17
		c0.95-3.41,1.61-6.63,1.61-10.22c0-13.25-11.07-15.9-20.34-15.9c-15.71,0-21.67,7.66-24.79,14.38c-2.65,5.39-4.54,13.63-4.54,19.21
		c0,11.54,8.14,15.99,20.35,15.99c11.45,0,17.6-3.97,20.63-6.81c3.79-3.5,4.83-6.62,5.68-9.27H414.86z M401.04,103.65
		c1.7-5.58,4.26-12.49,11.35-12.49c4.07,0,6.34,2.65,6.34,6.62c0,2.37-0.66,4.92-0.95,5.87H401.04z"/>
	<path className="st5" d="M477.95,131.56c-0.57-0.09-2.08-0.57-2.08-2.74c0-1.42,0.38-3.12,0.76-4.64l5.68-21.76
		c0.38-1.99,0.85-3.97,0.85-6.06c0-5.39-3.03-8.14-5.96-9.65c-3.78-1.99-9.37-2.55-13.25-2.55c-4.07,0-10.41,0.66-15.23,3.31
		c-6.62,3.6-8.04,9.18-8.8,12.21h15.04c0.47-1.8,1.04-3.88,2.56-5.68c1.42-1.7,3.5-2.65,5.68-2.65c2.46,0,5.2,0.95,5.2,4.64
		c0,1.8-0.57,4.07-1.04,5.77l-0.47,1.89c-5.77,0.38-15.61,1.23-21.01,3.12c-2.18,0.76-9.18,3.22-11.26,11.83
		c-0.38,1.61-0.47,3.03-0.47,4.35c0,1.52,0.19,4.83,2.65,7.38c1.61,1.7,4.64,3.22,9.27,3.22c4.35,0,7.66-1.32,9.94-2.55
		c2.74-1.42,3.6-2.56,4.73-3.97c-0.09,0.66-0.19,1.42-0.19,2.08c0,3.22,1.99,3.41,4.07,3.5h13.06L477.95,131.56z M463.66,116.7
		c-0.95,3.12-1.33,4.35-2.74,5.87c-1.51,1.7-3.88,2.84-6.53,2.84c-4.16,0-5.01-3.12-5.01-5.01c0-2.37,0.95-5.58,3.78-7.67
		c3.03-2.18,8.42-2.84,12.21-3.03L463.66,116.7z"/>
	<path className="st5" d="M535.39,103.17c0.76-2.84,1.61-6.06,1.61-9.08c0-5.3-3.31-9.65-10.5-9.65c-6.06,0-10.98,2.65-15.71,6.34
		l1.42-5.49h-15.33l-12.3,47.31H500l7.85-30.28c1.04-4.26,1.61-5.58,3.31-7.29c1.7-1.61,3.88-2.65,5.87-2.65
		c3.22,0,4.16,2.37,4.16,4.26c0,1.7-0.85,4.92-1.51,7.29l-7.38,28.67h15.52L535.39,103.17z"/>
	<g>
		<path className="st6" d="M277.04,136.07l-1.11-0.02c14.86-0.58,26.73-12.75,26.73-27.69s-11.87-27.11-26.73-27.69l1.11-0.02
			c15.37,0,27.84,12.41,27.84,27.71S292.41,136.07,277.04,136.07z"/>
		<path className="st6" d="M274.83,136.07c-15.37,0-27.84-12.41-27.84-27.71s12.46-27.71,27.84-27.71l1.11,0.02
			c-14.86,0.58-26.73,12.75-26.73,27.69s11.87,27.11,26.73,27.69L274.83,136.07z"/>
	</g>
	<g>
		<g>
			<path className="st7" d="M275.86,84.85c0.95,4.2,2.03,7.54,3.24,10.03c1.21,2.49,3.05,5.18,5.53,8.08l1.07,1.23
				c3.23,3.74,4.85,7.49,4.85,11.23c0,4.13-1.45,7.68-4.34,10.64c-2.88,2.96-6.33,4.44-10.35,4.44c-4.01,0-7.46-1.48-10.35-4.44
				c-2.88-2.96-4.31-6.51-4.31-10.64c0-3.74,1.62-7.49,4.85-11.23l1.07-1.23c2.48-2.9,4.32-5.59,5.53-8.08
				C273.86,92.38,274.93,89.04,275.86,84.85z"/>
		</g>
		<g>
			<path className="st8" d="M265.67,119.97c0-2,1.62-3.62,3.62-3.62c2,0,3.62,1.62,3.62,3.62c0,2-1.62,3.62-3.62,3.62
				C267.29,123.59,265.67,121.97,265.67,119.97z"/>
			<path className="st8" d="M269.29,119.97"/>
		</g>
	</g>
</g>
<g>
	<path className="st9" d="M179.72,157.85h-7.08v4.57h7.9v3.57h-12.24v-19.23h11.84v3.57h-7.5v4h7.08V157.85z"/>
	<path className="st9" d="M193.73,165.61c-0.77,0.37-2.23,0.66-3.88,0.66c-4.51,0-7.39-2.77-7.39-7.13c0-4.05,2.8-7.39,7.99-7.39
		c1.14,0,2.4,0.2,3.31,0.54l-0.69,3.22c-0.51-0.23-1.28-0.43-2.43-0.43c-2.28,0-3.77,1.63-3.74,3.88c0,2.57,1.71,3.91,3.82,3.91
		c1.03,0,1.83-0.17,2.48-0.46L193.73,165.61z"/>
	<path className="st9" d="M209.29,161.5c0,1.83,0.06,3.31,0.11,4.48h-3.77l-0.2-1.97h-0.09c-0.54,0.86-1.85,2.28-4.37,2.28
		c-2.85,0-4.91-1.77-4.91-6.08v-8.19h4.34v7.5c0,2.03,0.66,3.25,2.2,3.25c1.2,0,1.91-0.83,2.17-1.51c0.11-0.26,0.17-0.57,0.17-0.94
		v-8.3h4.34V161.5z"/>
	<path className="st9" d="M224.39,162.61c0,1.31,0.06,2.6,0.23,3.37h-3.91l-0.26-1.4h-0.09c-0.91,1.11-2.34,1.71-3.99,1.71
		c-2.82,0-4.51-2.05-4.51-4.28c0-3.62,3.25-5.34,8.19-5.34v-0.17c0-0.74-0.4-1.8-2.54-1.8c-1.43,0-2.94,0.48-3.85,1.03l-0.8-2.77
		c0.97-0.54,2.88-1.25,5.42-1.25c4.65,0,6.11,2.74,6.11,6.02V162.61z M220.19,159.39c-2.28,0-4.05,0.54-4.05,2.2
		c0,1.11,0.74,1.66,1.71,1.66c1.06,0,1.97-0.71,2.25-1.6c0.06-0.23,0.09-0.49,0.09-0.74V159.39z"/>
	<path className="st9" d="M241.37,145.69v16.12c0,1.57,0.06,3.22,0.11,4.17h-3.85l-0.17-2.03h-0.09c-0.86,1.54-2.57,2.34-4.37,2.34
		c-3.31,0-5.96-2.82-5.96-7.16c-0.03-4.68,2.91-7.42,6.25-7.42c1.74,0,3.05,0.63,3.68,1.57h0.06v-7.59H241.37z M237.03,157.96
		c0-0.26,0-0.54-0.06-0.8c-0.26-1.17-1.2-2.11-2.54-2.11c-2,0-3,1.77-3,3.97c0,2.34,1.17,3.82,2.97,3.82c1.26,0,2.28-0.86,2.54-2.08
		c0.06-0.29,0.09-0.63,0.09-0.97V157.96z"/>
	<path className="st9" d="M251.27,166.29c-4.08,0-7.22-2.68-7.22-7.19c0-4.51,2.97-7.39,7.48-7.39c4.25,0,7.1,2.94,7.1,7.16
		c0,5.08-3.62,7.42-7.33,7.42H251.27z M251.39,163.18c1.71,0,2.77-1.68,2.77-4.2c0-2.05-0.8-4.17-2.77-4.17
		c-2.05,0-2.85,2.11-2.85,4.2c0,2.37,1,4.17,2.82,4.17H251.39z"/>
	<path className="st9" d="M261.32,156.62c0-2.05-0.03-3.4-0.11-4.59h3.71l0.17,2.6h0.11c0.71-2.05,2.4-2.91,3.77-2.91
		c0.4,0,0.6,0.03,0.91,0.08v4.08c-0.34-0.06-0.69-0.11-1.17-0.11c-1.6,0-2.68,0.83-2.97,2.2c-0.06,0.29-0.09,0.6-0.09,0.97v7.05
		h-4.34V156.62z"/>
	<path className="st9" d="M292.17,165.44c-0.77,0.37-2.6,0.83-4.91,0.83c-6.65,0-10.07-4.17-10.07-9.64c0-6.53,4.68-10.16,10.5-10.16
		c2.25,0,3.97,0.46,4.74,0.83l-0.91,3.45c-0.86-0.37-2.08-0.71-3.65-0.71c-3.42,0-6.11,2.08-6.11,6.36c0,3.82,2.28,6.25,6.13,6.25
		c1.34,0,2.77-0.26,3.65-0.63L292.17,165.44z"/>
	<path className="st9" d="M296.94,150.32c-1.37,0-2.25-0.97-2.25-2.17c0-1.23,0.91-2.17,2.31-2.17c1.4,0,2.25,0.94,2.28,2.17
		c0,1.2-0.88,2.17-2.31,2.17H296.94z M294.8,165.98v-13.95h4.34v13.95H294.8z"/>
	<path className="st9" d="M314.23,162.61c0,1.31,0.06,2.6,0.23,3.37h-3.91l-0.26-1.4h-0.08c-0.91,1.11-2.34,1.71-4,1.71
		c-2.83,0-4.51-2.05-4.51-4.28c0-3.62,3.25-5.34,8.19-5.34v-0.17c0-0.74-0.4-1.8-2.54-1.8c-1.43,0-2.94,0.48-3.85,1.03l-0.8-2.77
		c0.97-0.54,2.88-1.25,5.42-1.25c4.65,0,6.11,2.74,6.11,6.02V162.61z M310.04,159.39c-2.28,0-4.05,0.54-4.05,2.2
		c0,1.11,0.74,1.66,1.71,1.66c1.06,0,1.97-0.71,2.25-1.6c0.06-0.23,0.08-0.49,0.08-0.74V159.39z"/>
	<path className="st9" d="M319.89,166.29c-1.46,0-2.51-1.11-2.51-2.62c0-1.57,1.06-2.65,2.57-2.65c1.48,0,2.51,1.06,2.54,2.65
		c0,1.51-1.03,2.62-2.57,2.62H319.89z"/>
	<path className="st9" d="M331.05,146.75h4.34v15.58h7.65v3.65h-11.98V146.75z"/>
	<path className="st9" d="M350.17,148.23v3.79h3.11v3.2h-3.11v5.05c0,1.68,0.43,2.45,1.71,2.45c0.6,0,0.88-0.03,1.28-0.11l0.03,3.28
		c-0.54,0.2-1.6,0.37-2.8,0.37c-1.4,0-2.57-0.49-3.28-1.2c-0.8-0.83-1.2-2.17-1.2-4.14v-5.71h-1.85v-3.2h1.85v-2.62L350.17,148.23z"
		/>
	<path className="st9" d="M369.17,145.69v16.12c0,1.57,0.06,3.22,0.11,4.17h-3.85l-0.17-2.03h-0.09c-0.86,1.54-2.57,2.34-4.37,2.34
		c-3.31,0-5.96-2.82-5.96-7.16c-0.03-4.68,2.91-7.42,6.25-7.42c1.74,0,3.05,0.63,3.68,1.57h0.06v-7.59H369.17z M364.84,157.96
		c0-0.26,0-0.54-0.06-0.8c-0.26-1.17-1.2-2.11-2.54-2.11c-2,0-3,1.77-3,3.97c0,2.34,1.17,3.82,2.97,3.82c1.25,0,2.28-0.86,2.54-2.08
		c0.06-0.29,0.09-0.63,0.09-0.97V157.96z"/>
	<path className="st9" d="M384.27,162.61c0,1.31,0.06,2.6,0.23,3.37h-3.91l-0.26-1.4h-0.08c-0.91,1.11-2.34,1.71-4,1.71
		c-2.83,0-4.51-2.05-4.51-4.28c0-3.62,3.25-5.34,8.19-5.34v-0.17c0-0.74-0.4-1.8-2.54-1.8c-1.43,0-2.94,0.48-3.85,1.03l-0.8-2.77
		c0.97-0.54,2.88-1.25,5.42-1.25c4.65,0,6.11,2.74,6.11,6.02V162.61z M380.08,159.39c-2.28,0-4.05,0.54-4.05,2.2
		c0,1.11,0.74,1.66,1.71,1.66c1.06,0,1.97-0.71,2.25-1.6c0.06-0.23,0.08-0.49,0.08-0.74V159.39z"/>
	<path className="st9" d="M389.93,166.29c-1.46,0-2.51-1.11-2.51-2.62c0-1.57,1.06-2.65,2.57-2.65c1.48,0,2.51,1.06,2.54,2.65
		c0,1.51-1.03,2.62-2.57,2.62H389.93z"/>
</g>
</svg>

);

export default LogoTecno;
